<template>
    <div class="start">
        <div class="start__logo" />

        <div class="start__window">
            <div class="start__block">
                <div class="start__header h2">
                    Вход в систему
                </div>

                <form
                    class="start__form form"
                    @submit.prevent="submit"
                >
                    <field
                        v-model="values.username"
                        :valid="valid.username"
                        :error="errors.username"
                        type="username"
                        name="username"
                        placeholder="Код сотрудника"
                        class="form__field"
                    />
                    <field
                        v-model="values.password"
                        :valid="valid.password"
                        :error="errors.password"
                        type="password"
                        name="password"
                        placeholder="Персональный ключ"
                        class="form__field"
                    />

                    <div
                        v-for="message in messages"
                        class="form__message form__message--error"
                    >
                        {{ message }}
                    </div>

                    <div class="form__remark">
                        Не сообщайте код сотрудника и персональный ключ во избежание утечки информации *
                    </div>

                    <div class="form__panel">
                        <button
                            type="submit"
                            class="button button--red start__submit"
                        >
                            Войти в систему
                        </button>
                    </div>
                </form>
            </div>

            <div class="start__recover">
                <a
                    class="a a--darkgray"
                    href="#"
                    @click.prevent="showRecover"
                >Забыли персональный ключ?</a>
            </div>

            <div
                v-if="isLoading"
                class="start__loader"
            >
                <div class="start__loader-label">
                    Выполняется вход
                </div>
                <div class="start__loader-spinner spinner" />
            </div>
        </div>

        <modal
            :is-shown="isRecover"
            @hide="hideRecover"
        >
            <h3
                slot="header"
                class="modal__header h2"
            >
                Забыли персональный ключ?
            </h3>
            <div
                slot="content"
                class="modal__content text"
            >
                <p>Вам необходимо обратиться к управляющему</p>
            </div>
            <div
                slot="footer"
                class="modal__panel"
            >
                <button
                    class="modal__submit button button--green"
                    @click="hideRecover"
                >
                    Закрыть
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import _defaults from 'lodash/defaults';
    import _keys from 'lodash/keys';
    import { logger, merchants } from '@/services';
    import modal from '@/components/modal.vue';
    import field from '@/components/field.vue';

    const fields = ['username', 'password', 'hash'];

    export default {
        components: {
            modal,
            field
        },
        data() {
            return {
                values: {
                    username: '',
                    password: '',
                    hash: this.$router.currentRoute.query.hash || ''
                },
                errors: {},
                messages: [],
                isLoading: false,
                isRecover: false
            };
        },
        computed: {
            valid() {
                let valid = {};
                for (let field of fields) {
                    valid[field] = (this.values[field] !== '');
                }
                return valid;
            },
            isValid() {
                return _keys(this.valid).length == fields.length;
            }
        },
        methods: {
            submit() {
                if (this.isLoading) {
                    return;
                }

                let params = _defaults({}, this.values);

                console.info('Authorization', params);
                logger.info(`[ ${ __filename } ] Authorization: username = ${ params.username }, password = ${ params.password }, hash = ${ params.hash }`);

                this.errors = {};
                this.messages = [];

                this.isLoading = true;

                merchants.authorizeMerchant(params).then((data) => {
                    console.info('Authorization completed');
                    logger.info(`[ ${ __filename } ] Authorization completed`);

                    this.$store.dispatch('authUser', data.user, data.lombard);
                }).catch((error) => {
                    if (error.response && error.response.data) {
                        if (error.response.data.detail) {
                            this.messages = [ error.response.data.detail ];
                            delete error.response.data.detail;
                        }
                        this.errors = error.response.data;
                    } else {
                        const err = new Error('Не удалось авторизоваться');
                        err.parent = error;
                        this.$store.dispatch('showError', { err, vm: this });
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            showRecover() {
                this.isRecover = true;
            },
            hideRecover() {
                this.isRecover = false;
            }
        }
    };
</script>
