import Vue from 'vue';
import VueRouter from 'vue-router';
import index from '@/views/merchant/index.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'index',
        component: index
    }, {
        path: '/client-search',
        name: 'client-search',
        component: () => import(/* webpackChunkName: "merchant.client-search" */ '@/views/merchant/client-search.vue')
    }, {
        path: '/client-create',
        name: 'client-create',
        component: () => import(/* webpackChunkName: "merchant.client-create" */ '@/views/merchant/client-create.vue')
    }, {
        path: '/client-update',
        name: 'client-update',
        component: () => import(/* webpackChunkName: "merchant.client-update" */ '@/views/merchant/client-update.vue')
    }, {
        path: '/pawn-create',
        name: 'pawn-create',
        component: () => import(/* webpackChunkName: "merchant.pawn-create" */ '@/views/merchant/pawn-create.vue')
    }
];

const router = new VueRouter({
    mode: (process.env.NODE_ENV === 'production') ? 'history' : 'hash',
    linkActiveClass: 'is-subactive',
    linkExactActiveClass: 'is-active',
    routes
});

export default router;
