<template>
    <div class="app__block">
        <aside-view class="app__aside" />
        <main-view class="app__main" />
    </div>
</template>

<script>
    import asideView from './index/aside.vue';
    import mainView from './index/main.vue';

    export default {
        components: {
            asideView,
            mainView
        }
    };
</script>
