<template>
    <div
        class="news-list"
        :class="className"
    >
        <div
            v-if="items.length"
            class="news-list__list"
        >
            <div
                v-for="item in items"
                :key="item.id"
                :class="itemClassName(item)"
                class="news-list__item news-item"
            >
                <div
                    v-if="item.image"
                    class="news-item__pic"
                >
                    <img
                        :src="item.image"
                        :alt="item.header"
                        class="news-item__img"
                    >
                </div>
                <div class="news-item__cont">
                    <div class="news-item__header h2">
                        {{ item.header }}
                    </div>
                    <div
                        class="news-item__text text"
                        v-html="item.content"
                    />
                    <div
                        v-if="item.label || item.date"
                        class="news-item__panel"
                    >
                        <div
                            v-if="item.label"
                            class="news-item__panel-item news-item__label"
                            :class="item.type ? 'news-item__label--' + item.type : ''"
                        >
                            {{ item.label }}
                        </div>
                        <div
                            v-if="item.date"
                            class="news-item__panel-item news-item__date"
                        >
                            {{ item.date | date('HH:mm DD.MM.YYYY') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="news-list__empty"
        >
            <slot name="empty">
                Список пуст
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
                default() { return []; }
            },
            type: {
                type: String,
                default: ''
            }
        },
        computed: {
            className() {
                return this.type ? 'news-list--' + this.type : '';
            }
        },
        methods: {
            itemClassName(item) {
                let mods = [];
                if (this.type) {
                    mods.push('news-item--' + this.type);
                }
                if (item.image) {
                    mods.push('news-item--pic');
                }
                return mods.join(' ');
            }
        }
    };
</script>
