<template>
    <main class="main">
        <div class="main__body">
            <div
                v-if="mainNews.length"
                class="main__section"
            >
                <h3 class="h3">
                    Важные уведомления
                </h3>
                <news-list
                    :items="mainNews"
                    type="row"
                />
            </div>
            <div
                v-if="otherNews.length"
                class="main__section"
            >
                <h3 class="h3">
                    Прочие уведомления
                </h3>
                <news-list
                    :items="otherNews"
                    type="col"
                />
            </div>
        </div>
    </main>
</template>

<script>
    import { news } from '@/services';
    import newsList from '@/components/news-list.vue';

    export default {
        components: {
            newsList
        },
        data() {
            return {
                news: []
            };
        },
        computed: {
            mainNews() {
                let items = [];
                for (let item of this.news) {
                    if (item.is_main) {
                        items.push(item);
                    }
                }
                return items;
            },
            otherNews() {
                let items = [];
                for (let item of this.news) {
                    if (!item.is_main) {
                        items.push(item);
                    }
                }
                return items;
            }
        },
        created() {
            this.$store.dispatch('showLoader', { label: 'Загрузка новостей' });

            news.getNews({ is_active: true }).then((items) => {
                this.news = items;
            }).catch((error) => {
                const err = new Error('Не удалось загрузить новости');
                err.parent = error;
                this.$store.dispatch('showError', { err, vm: this });
            }).finally(() => {
                this.$store.dispatch('hideLoader');
            });
        }
    };
</script>
