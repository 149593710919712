<template>
    <div class="hello">
        <div class="hello__block">
            <div class="hello__icon" />
            <div class="hello__person">
                <div class="hello__name">
                    {{ person }}
                </div>
                <div class="hello__comment">
                    {{ occupation }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            user() {
                return this.$store.state.user;
            },
            person() {
                if (this.user) {
                    if (this.user.first_name && this.user.last_name) {
                        return this.user.first_name.charAt(0) + '. ' + this.user.last_name;
                    } else if (this.user.last_name) {
                        return this.user.last_name;
                    }
                }
                return '';
            },
            occupation() {
                if (this.user) {
                    return this.user.occupation;
                }
                return '';
            }
        }
    };
</script>
