import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        lombard: null,
        error: null,
        loader: null
    },
    mutations: {
        user(state, user) {
            state.user = user;
        },
        lombard(state, lombard) {
            state.lombard = lombard;
        },
        error(state, error) {
            state.error = error;
        },
        loader(state, loader) {
            state.loader = loader;
        }
    },
    actions: {
        authUser(context, user, lombard) {
            context.commit('user', user);
            context.commit('lombard', lombard);
        },
        deauthUser(context) {
            context.commit('user', null);
            context.commit('lombard', null);
        },
        showError(context, error) {
            context.commit('error', error);
        },
        hideError(context) {
            context.commit('error', null);
        },
        showLoader(context, loader) {
            context.commit('loader', loader);
        },
        hideLoader(context) {
            context.commit('loader', null);
        }
    }
});
