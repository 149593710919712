<template>
    <aside
        class="aside"
        :class="{ 'is-active': isChat }"
    >
        <div class="aside__block">
            <router-link
                :to="{ name: 'index' }"
                class="aside__logo"
            />

            <hello class="aside__hello" />

            <nav class="nav aside__nav">
                <div class="nav__header">
                    Основные действия
                </div>

                <router-link
                    :to="{ name: 'pawn-create' }"
                    class="nav__link nav__link--pawn"
                >
                    <span class="nav__span">Новый залог</span>
                </router-link>
                <router-link
                    :to="{ name: 'client-search' }"
                    class="nav__link nav__link--search"
                >
                    <span class="nav__span">Поиск клиента</span>
                </router-link>
                <a
                    href="#"
                    class="nav__link nav__link--chat"
                    :class="{ 'is-active': isChat }"
                    @click.prevent="toggleChat"
                >
                    <span class="nav__span">Чат товароведов</span>
                </a>
                <a
                    href="#"
                    class="nav__link nav__link--support"
                    @click.prevent="reject"
                >
                    <span class="nav__span">Поддержка платформы</span>
                </a>
            </nav>

            <nav class="nav aside__nav">
                <div class="nav__header">
                    Прочие действия
                </div>

                <a
                    href="#"
                    class="nav__link nav__link--exit"
                    @click.prevent="deauthorize"
                >
                    <span class="nav__span">Закрыть смену</span>
                </a>
            </nav>

            <chat
                v-if="isChat"
                class="aside__chat"
                @hide="hideChat"
            />
        </div>
        <transition name="bg">
            <div
                v-if="isChat"
                class="aside__bg"
                @click.prevent="hideChat"
            />
        </transition>
    </aside>
</template>

<script>
    import { logger, merchants } from '@/services';
    import hello from '@/components/hello.vue';
    import chat from '@/components/chat.vue';

    export default {
        components: {
            hello,
            chat
        },
        data() {
            return {
                isChat: false
            };
        },
        methods: {
            reject() {
                alert('Еще не работает');
            },
            showChat() {
                this.isChat = true;
            },
            hideChat() {
                this.isChat = false;
            },
            toggleChat() {
                this.isChat = !this.isChat;
            },
            deauthorize() {
                this.$store.dispatch('showLoader', { label: 'Идет деавторизация' });

                merchants.deauthorizeMerchant().then((data) => {
                    console.info('Deauthorization completed');
                    logger.info(`[ ${ __filename } ] Deauthorization completed`);

                    this.$store.dispatch('deauthUser');
                    if (this.$router.currentRoute.name !== 'index') {
                        this.$router.push({ name: 'index' });
                    }
                }).catch((error) => {
                    const err = new Error('Не удалось деавторизоваться');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            }
        }
    };
</script>
