<template>
    <transition name="chat">
        <div class="chat">
            <div
                ref="area"
                class="chat__area"
                @scroll="scroll"
            >
                <div
                    v-for="item in messages"
                    :key="item.id"
                    class="chat__item"
                    :class="{ 'chat__item--own': (item.user_code === user_code) }"
                >
                    <div class="chat__item-head">
                        <div class="chat__item-person">
                            {{ item.user_name }}
                        </div>
                        <div class="chat__item-date">
                            {{ item.date | date('HH:mm:ss DD.MM.YYYY') }}
                        </div>
                    </div>
                    <div class="chat__item-message">
                        {{ item.content }}
                    </div>
                </div>
            </div>
            <form
                class="chat__bar"
                @submit.prevent="send"
            >
                <input
                    v-model="message"
                    :disabled="!isConnected"
                    type="text"
                    name="message"
                    class="chat__input input"
                >
                <button
                    :disabled="!isValid"
                    type="submit"
                    class="chat__button inpton inpton--green"
                />
            </form>
        </div>
    </transition>
</template>

<script>
    import { chat as Chat } from '@/services';

    export default {
        data() {
            return {
                message: '',
                items: [],
                limit: 10,
                prevEnabled: true,
                chat: undefined,
                isConnected: false
            };
        },
        computed: {
            isValid() {
                return this.message !== '';
            },
            user() {
                return this.$store.state.user;
            },
            user_name() {
                if (this.user && this.user.first_name && this.user.last_name) {
                    return this.user.last_name + ' ' + this.user.first_name;
                }
                return '';
            },
            user_code() {
                if (this.user && this.user.id) {
                    return this.user.id;
                }
                return '';
            },
            messages() {
                return this.items.slice().reverse();
            }
        },
        created() {
            this.chat = new Chat();

            this.chat.onEvent('open', () => {
                console.log('Chat is opened');
                this.isConnected = true;
            });
            this.chat.onEvent('close', (isOK, e) => {
                if (isOK) {
                    console.log('Chat is closed');
                } else {
                    console.warn(`Chat is closed with code ${e.code}: ${e.reason}`);
                }
                this.isConnected = false;
            });
            this.chat.onEvent('error', (e) => {
                console.error('Chat has received an error');
            });
            this.chat.onEvent('message', (data) => {
                console.log('Chat has received a message', data);
                this.appendNext(data);
            });

            this.chat.openChat();

            this.loadPrev().then((items) => {
                const el = this.$refs.area;
                this.$nextTick(() => {
                    el.scrollTop = el.scrollHeight - el.clientHeight;
                });
            });
        },
        destroyed() {
            this.chat.closeChat();
        },
        methods: {
            scroll(e) {
                const el = this.$refs.area;

                if (this.prevEnabled && el.scrollTop === 0) {
                    const scrollHeight = el.scrollHeight;

                    this.loadPrev().then((items) => {
                        this.$nextTick(() => {
                            el.scrollTop = el.scrollHeight - scrollHeight;
                        });
                    });
                }
            },
            loadPrev() {
                return this.chat.getMessages({ limit: this.limit, offset: this.items.length }).then((items) => {
                    if (items.length < this.limit) {
                        this.prevEnabled = false;
                    }

                    this.items = this.items.concat(items);
                    return items;
                }).catch((error) => {
                    const err = new Error('Не удалось загрузить сообщения');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                });
            },
            appendNext(item) {
                this.items.unshift(item);

                const el = this.$refs.area;

                if (el.scrollTop === el.scrollHeight - el.clientHeight) {
                    this.$nextTick(() => {
                        el.scrollTop = el.scrollHeight - el.clientHeight;
                    });
                }
            },
            send() {
                this.chat.send({
                    content: this.message,
                    user_name: this.user_name,
                    user_code: this.user_code
                });
                this.message = '';
            }
        }
    };
</script>
