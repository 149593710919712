import Vue from 'vue';

import router from './router/merchant';
import store from './store/merchant';
import app from './merchant.vue';

import filters from './utils/filters';
import plugins from './utils/plugins';
import logger from './services/logger';

Vue.config.errorHandler = (err, vm, info) => {
    console.error('Global error', err);
    logger.report({ err, vm, info });
};

for (let filter in filters) {
    Vue.filter(filter, filters[filter]);
}
for (let plugin in plugins) {
    Vue.use(plugins[plugin]);
}

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(app)
});
